import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src311988156/src/mad-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Button, RadioGroup, RadioButton } from '@madup-inc/mad-design';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "radio"
    }}>{`Radio`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <h3 {...{
      "id": "radiogroup-properties"
    }}>{`RadioGroup Properties`}</h3>
    <Props of={RadioGroup} mdxType="Props" />
    <h3 {...{
      "id": "radiobutton-properties"
    }}>{`RadioButton Properties`}</h3>
    <Props of={RadioButton} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={2} __code={'<RadioGroup\n  name=\"developer\"\n  onChange={value => console.log(`${value} developer`)}\n  defaultValue=\"front\"\n>\n  <RadioButton content=\"프론트앤드\" value=\"front\" />\n  <RadioButton content=\"백앤드\" value=\"back\" />\n  <RadioButton content=\"풀스택\" value=\"full\" />\n  <RadioButton content=\"해커\" value=\"hack\" disabled />\n</RadioGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      RadioGroup,
      RadioButton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <RadioGroup name="developer" onChange={value => console.log(`${value} developer`)} defaultValue="front" mdxType="RadioGroup">
    <RadioButton content="프론트앤드" value="front" mdxType="RadioButton" />
    <RadioButton content="백앤드" value="back" mdxType="RadioButton" />
    <RadioButton content="풀스택" value="full" mdxType="RadioButton" />
    <RadioButton content="해커" value="hack" disabled mdxType="RadioButton" />
  </RadioGroup>
    </Playground>
    <h2 {...{
      "id": "outline-style"
    }}>{`Outline style`}</h2>
    <Playground __position={3} __code={'<RadioGroup\n  name=\"developer\"\n  onChange={value => console.log(`${value} developer`)}\n  defaultValue=\"front\"\n  optionType=\"outline\"\n>\n  <RadioButton content=\"프론트앤드\" value=\"front\" />\n  <RadioButton content=\"백앤드\" value=\"back\" />\n  <RadioButton content=\"풀스택\" value=\"full\" />\n  <RadioButton content=\"해커\" value=\"hack\" disabled />\n</RadioGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      RadioGroup,
      RadioButton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <RadioGroup name="developer" onChange={value => console.log(`${value} developer`)} defaultValue="front" optionType="outline" mdxType="RadioGroup">
    <RadioButton content="프론트앤드" value="front" mdxType="RadioButton" />
    <RadioButton content="백앤드" value="back" mdxType="RadioButton" />
    <RadioButton content="풀스택" value="full" mdxType="RadioButton" />
    <RadioButton content="해커" value="hack" disabled mdxType="RadioButton" />
  </RadioGroup>
    </Playground>
    <h2 {...{
      "id": "card-style"
    }}>{`Card style`}</h2>
    <Playground __position={4} __code={'<RadioGroup\n  name=\"developer\"\n  onChange={value => console.log(`${value} developer`)}\n  defaultValue=\"front\"\n  optionType=\"card\"\n>\n  <RadioButton content=\"프론트앤드\" value=\"front\" />\n  <RadioButton content=\"백앤드\" value=\"back\" />\n  <RadioButton content=\"풀스택\" value=\"full\" />\n  <RadioButton content=\"해커\" value=\"hack\" disabled />\n</RadioGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      RadioGroup,
      RadioButton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <RadioGroup name="developer" onChange={value => console.log(`${value} developer`)} defaultValue="front" optionType="card" mdxType="RadioGroup">
    <RadioButton content="프론트앤드" value="front" mdxType="RadioButton" />
    <RadioButton content="백앤드" value="back" mdxType="RadioButton" />
    <RadioButton content="풀스택" value="full" mdxType="RadioButton" />
    <RadioButton content="해커" value="hack" disabled mdxType="RadioButton" />
  </RadioGroup>
    </Playground>
    <h2 {...{
      "id": "card-style-custom-content"
    }}>{`Card style custom content`}</h2>
    <Playground __position={5} __code={'<RadioGroup\n  name=\"landmark\"\n  onChange={value => alert(`${value} landmark`)}\n  defaultValue=\"neuschwanstein\"\n  optionType=\"card\"\n>\n  <RadioButton\n    hasPadding={true}\n    content={\n      <div>\n        <h2>Roma Colosseum</h2>\n        <p>\n          로마의 콜로세움은 오랫동안 이탈리아의 주요 관광지로 활약했습니다.\n        </p>\n      </div>\n    }\n    value=\"roma\"\n  />\n  <RadioButton\n    hasPadding={true}\n    content={\n      <div>\n        <h2>Istanbul Sophia</h2>\n        <p>\n          500년간 이슬람 사원으로 쓰이면서 매우 독특한 문화유산으로\n          변했습니다.\n        </p>\n      </div>\n    }\n    value=\"istanbul\"\n  />\n  <RadioButton\n    hasPadding={true}\n    content={\n      <div>\n        <h2>Neuschwanstein</h2>\n        <p>\n          바이에른의 노이슈반스타인성은 디즈니 성으로도 유명한 근대 문화\n          유산입니다.\n        </p>\n      </div>\n    }\n    value=\"neuschwanstein\"\n  />\n  <RadioButton\n    hasPadding={true}\n    content={\n      <div>\n        <h2>Palmyra Syria</h2>\n        <p>아직 준비중입니다.</p>\n      </div>\n    }\n    value=\"palmyra\"\n    disabled\n  />\n</RadioGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      RadioGroup,
      RadioButton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <RadioGroup name="landmark" onChange={value => alert(`${value} landmark`)} defaultValue="neuschwanstein" optionType="card" mdxType="RadioGroup">
    <RadioButton hasPadding={true} content={<div>
          <h2>Roma Colosseum</h2>
          <p>
            로마의 콜로세움은 오랫동안 이탈리아의 주요 관광지로 활약했습니다.
          </p>
        </div>} value="roma" mdxType="RadioButton" />
    <RadioButton hasPadding={true} content={<div>
          <h2>Istanbul Sophia</h2>
          <p>
            500년간 이슬람 사원으로 쓰이면서 매우 독특한 문화유산으로 변했습니다.
          </p>
        </div>} value="istanbul" mdxType="RadioButton" />
    <RadioButton hasPadding={true} content={<div>
        <h2>Neuschwanstein</h2>
        <p>
          바이에른의 노이슈반스타인성은 디즈니 성으로도 유명한 근대 문화 유산입니다.
        </p>
      </div>} value="neuschwanstein" mdxType="RadioButton" />
    <RadioButton hasPadding={true} content={<div>
        <h2>Palmyra Syria</h2>
        <p>
          아직 준비중입니다.
        </p>
      </div>} value="palmyra" disabled mdxType="RadioButton" />
  </RadioGroup>
    </Playground>
    <h2 {...{
      "id": "card-style-haspadding"
    }}>{`Card style hasPadding`}</h2>
    <Playground __position={6} __code={'<RadioGroup\n  name=\"padding\"\n  onChange={value => alert(`${value}`)}\n  defaultValue=\"def\"\n  optionType=\"card\"\n>\n  <RadioButton hasPadding={false} content=\"noPadding\" value=\"not\" />\n  <RadioButton hasPadding={true} content=\"hasPadding\" value=\"has\" />\n  <RadioButton content=\"default\" value=\"def\" />\n</RadioGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      RadioGroup,
      RadioButton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <RadioGroup name="padding" onChange={value => alert(`${value}`)} defaultValue="def" optionType="card" mdxType="RadioGroup">
    <RadioButton hasPadding={false} content="noPadding" value="not" mdxType="RadioButton" />
    <RadioButton hasPadding={true} content="hasPadding" value="has" mdxType="RadioButton" />
    <RadioButton content="default" value="def" mdxType="RadioButton" />
  </RadioGroup>
    </Playground>
    <h2 {...{
      "id": "vertical-direction"
    }}>{`Vertical direction`}</h2>
    <Playground __position={7} __code={'<RadioGroup\n  name=\"padding\"\n  onChange={value => alert(`${value}`)}\n  direction=\"vertical\"\n  defaultValue=\"1\"\n>\n  <RadioButton content=\"item1\" value=\"1\" />\n  <RadioButton content=\"item2\" value=\"2\" />\n  <RadioButton content=\"item3\" value=\"3\" />\n</RadioGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      RadioGroup,
      RadioButton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <RadioGroup name="padding" onChange={value => alert(`${value}`)} direction="vertical" defaultValue="1" mdxType="RadioGroup">
    <RadioButton content="item1" value="1" mdxType="RadioButton" />
    <RadioButton content="item2" value="2" mdxType="RadioButton" />
    <RadioButton content="item3" value="3" mdxType="RadioButton" />
  </RadioGroup>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      